<template>
  <div>
    <div v-if="!isLoading">
      <div class="uk-text-large">{{ business.name }}</div>
      <div class="uk-margin-top uk-text-bold">Usuarios asociados</div>
      <table
        class="uk-table uk-table-striped uk-table-middle uk-table-hover uk-table-divider uk-margin-remove"
      >
        <thead>
          <tr>
            <th>Usuario</th>
            <th>Correo</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in business.users" :key="user.id">
            <td>{{ user.first_name }} {{ user.last_name }}</td>
            <td>{{ user.email }}</td>
          </tr>
        </tbody>
      </table>
      <parametrizations
        v-if="!constantsIsLoadingConstants"
        parametrizable-type="App\Models\Business"
        :parametrizable-id="business.id"
        :constant-values="business.constant_values"
        :constants="constantsConstants.constants"
        :refetch-function="fetchBusiness"
      />
      <div v-else class="uk-text-center">
        <div uk-spinner></div>
      </div>
    </div>

    <div v-else class="uk-text-center">
      <div uk-spinner></div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "BusinessesShow",

  // props: ["payload"],

  data() {
    return {
      mode: "new",
      business: null,
      isLoading: true,
    };
  },

  computed: {
    ...mapGetters(["constantsConstants", "constantsIsLoadingConstants"]),
  },

  mounted() {
    this.constantsFetchConstants();
    this.fetchBusiness();
  },

  methods: {
    ...mapActions(["constantsFetchConstants"]),
    fetchBusiness() {
      this.isLoading = true;
      this.axios
        .get("/businesses/" + this.$route.params.id)
        .then(({ data: { business } }) => {
          this.business = business;
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>